import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Image from '../components/image'
import Layout from '../components/layout'
import { Spacer as Space, Box, Text, Flex } from '../components/Base'

//Props are coming from that query at the bottom
const Post = props => {
  const post = props.data.mdx.frontmatter
  return (
    <Layout>
      <Title>✨{post.title}✨</Title>
      <div style={{ display: 'flex' }}>
        <ExampleImage>
          <Image imgUrl={post.img} height="300px" width="300px" />
        </ExampleImage>
        <Ingredients>
          <h1>Ingredients:</h1>
          {post.methods.map(function(item) {
            return <li>{item}</li>
          })}
        </Ingredients>
      </div>
      <Instructions>
        <h1>Instructions:</h1>
        {post.languages.map(function(instruction) {
          return <li>{instruction}</li>
        })}
      </Instructions>
    </Layout>
  )
}

export default Post

const ExampleImage = styled.div`
  display: flex;
  justify-content: center;
`
const Instructions = styled.div`
  padding: 10px;
  margin-top: 20px;
`
const Ingredients = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`
const Title = styled(Text)`
  color: #05167d;
  font-size: 50px;
  text-align: center;
  font-family: pacifico;
`

const ExecutiveSummary = styled(Flex)`
  flex-direction: column;
  align-items: left;
`

const Header = styled(Box)`
  width: 50%;
  border: 0.5px solid blue;
  outline-color: blue;
  outline-style: double;
  padding: 20px;
`

//Frontmatter is a keyword so it doesn't fall under "exports" (?)
export const postQuery = graphql`
  query postBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      excerpt
      frontmatter {
        title
        date
        auditors
        languages
        methods
        specification
        img
        sourceCodeRepo
        sourceCodeCommit
      }
      exports {
        overall {
          title
          description
        }
      }
      timeToRead
    }
  }
`
